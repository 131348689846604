export const ERROR_MESSAGES = {
  mandatoryFieldErrorMsg: '_This field is mandatory.',
  emailFieldErrorMsg: '_Email is invalid.',
  maxLengthErrorMsg: '_Max allowed characters are',
  dateErrorMsg: '_please enter valid date',
  fileFormatErrorMsg: '_File format is Invalid, please use csv file.',
  fileDataErrorMsg: '_File contains invalid data.',
  fileImportErrorMsg: '_The import failed, please try again.',
  timeInputValidation: '_Time is specified in format hh:mm',
  addTemplateModeSelection: "_Must specify at least one 'Timesheet' method",
  addTimeFieldDuplicate: '_Time field already added',
  addRuleDateValidation: '_Must specify date',
  addRuleDayValidation: '_Must specify day',
  breakValidation: '_The break must start before it ends',
  addRoleValidation: '_Must specify permission',
  otRuleTimeValidation: '_End time should be greater then start time',
  dateOrderValidation: '_End date should be greater then start date',
  editMasterRoleError: "_Can't update master role",
  editOtherProductEntryError: '_Not allowed to perform this action',
  addAddressValidation:
    '_Invalid selection: Please pick either a sender or a receiver.',
  maxAllowedValue: '_Max allowed value is ',
  phoneErrorMsg: '_please enter valid phone number',
};

export const CONFIRMATION_MESSAGES = {
  productDeleteConfirmationMsg: '_Are you sure you want to delete the product?',
  productStatusDeleteConfirmationMsg:
    '_Are you sure you want to delete the status?',
  customerDeleteConfirmationMsg:
    '_Are you sure you want to delete the customer?',
  templateDeleteConfirmationMsg:
    '_Are you sure you want to delete the product template?',
  timeGroupDeleteConfirmationMsg:
    '_Are you sure you want to delete the time group?',
  timeObjectDeleteConfirmationMsg:
    '_Are you sure you want to delete the time object?',
  timeTypeDeleteConfirmationMsg:
    '_Are you sure you want to delete the time type?',
  overtimeTemplateDeleteConfirmationMsg:
    '_Are you sure you want to delete the overtime template?',
  roleDeleteConfirmationMsg: '_Are you sure you want to delete the role?',
  productEntryDeleteConfirmationMsg:
    '_Are you sure you want to delete the product entry?',
  biogasEntryDeleteConfirmationMsg:
    '_Are you sure you want to delete the biogas entry?',
  milageDeleteConfirmationMsg: '_Are you sure you want to delete the mileage?',
  userDeleteConfirmationMsg: '_Are you sure you want to delete the user?',
  allowanceDeleteConfirmationMsg:
    '_Are you sure you want to delete the allowance?',
  assignmentDeleteConfirmationMsg:
    '_Are you sure you want to delete the assignment?',
  biogasCustomerDeleteConfirm: '_Are you sure you want to delete the customer?',
  biogasAddressDeleteConfirm: '_Are you sure you want to delete the address?',
  biogasAnalysisDeleteConfirm: '_Are you sure you want to delete the analysis?',
  biogasVehicleDeleteConfirm: '_Are you sure you want to delete the vehicle?',
  multiStatusChangeCaution:
    '_This action will change the status to: {0} for {1} product registrations.',
};

export const TOASTER_MESSAGES = {
  loggedInSuccessfully: '_Logged in successfully',
  loginUnsuccessful: '_Email or password is invalid',
  userNotFound: '_You are not registered yet, Please contact administrator',
  forgotPasswordSuccessful:
    '_You will receive an email if you are a part of the system',
  internalServerError: '_Something went wrong, Please try again',
  tokenExpired: '_Your token has expired, Please login again',
  productAdded: '_Product added successfully.',
  productUpdated: '_Product updated successfully.',
  productDeleted: '_Product deleted successfully.',
  statusAdded: '_Product status added successfully.',
  statusUpdated: '_Product status updated successfully.',
  statusDeleted: '_Product status deleted successfully.',
  templateAdded: '_Product template added successfully.',
  templateUpdated: '_Product template updated successfully.',
  templateDeleted: '_Product template deleted successfully.',
  customerAdded: '_Customer added successfully.',
  customerUpdated: '_Customer updated successfully.',
  customerDeleted: '_Customer deleted successfully.',
  importSuccess: '_customers was successfully imported',
  importFailure: '_customers could not be imported',
  selectProductForAddTemplate:
    '_At least one product must be selected to create a template.',
  selectProductForUpdateTemplate:
    '_At least one product must be selected to update a template.',
  timeGroupAdded: '_Time group added successfully.',
  timeGroupUpdated: '_Time group updated successfully.',
  timeGroupDeleted: '_Time group deleted successfully.',
  timeTypeAdded: '_Time type added successfully.',
  timeTypeUpdated: '_Time type updated successfully.',
  timeTypeDeleted: '_Time type deleted successfully.',
  timeObjectAdded: '_Time object added successfully.',
  timeObjectUpdated: '_Time object updated successfully.',
  timeObjectDeleted: '_Time object deleted successfully.',
  overtimeTemplateAdded: '_Overtime template added successfully.',
  overtimeTemplateUpdated: '_Overtime template updated successfully.',
  overtimeTemplateDeleted: '_Overtime template deleted successfully.',
  roleAdded: '_Role added successfully.',
  roleUpdated: '_Role updated successfully.',
  roleDeleted: '_Role deleted successfully.',
  selectCustomer: '_A customer must be selected.',
  selectProduct: '_No products to save.',
  productEntryAdded: '_Product entry added successfully.',
  productEntryUpdated: '_Product entry updated successfully.',
  productEntryDeleted: '_Product entry deleted successfully.',
  mileageAdded: '_Mileage added successfully.',
  mileageUpdated: '_Mileage updated successfully.',
  mileageDeleted: '_Mileage deleted successfully.',
  userAdded: '_User added successfully.',
  userUpdated: '_User updated successfully.',
  userDeleted: '_User deleted successfully.',
  userInvited: '_User invited successfully.',
  selectAllowance: '_No Allowance to save.',
  allowanceAdded: '_Allowance added successfully.',
  allowanceUpdated: '_Allowance updated successfully.',
  allowanceDeleted: '_Allowance deleted successfully.',
  allowanceTimeCaution: '_Departure must take place before returning home',
  summaryLockSuccess: "_User's summary locked successfully.",
  summaryUnlockSuccess: "_User's summary unlocked successfully.",
  startAndEndTimeValidation: '_Start time must take place before end time.',
  exceedingBreaksCaution: '_Breaks should occur within daytime hours.',
  overlappingBreaksCaution: '_Break overlaps with other breaks.',
  selectDayCaution: '_Selece atleast one day to add time logs.',
  favSuccess: '_Report added to favourites succeffully.',
  unFavSuccess: '_Report removed from favourites succeffully.',
  timeAdded: '_Time added successfully.',
  timeUpdated: '_Time updated successfully.',
  timeDeleted: '_Time deleted successfully.',
  exceedingAbsenceCaution: '_The total time exceeds 24 hours.',
  vacationCaution: '_Must not enter anything else when holiday is selected.',
  exceedingTimeBankInCaution:
    '_Cannot add more time to the time bank than has been worked.',
  timeBankCaution: "_Can't both deposit and withdraw from the time bank.",
  categorizeHoursCaution: '{0} hours left to categorize.',
  startAndEndTimeCaution: '_Must add start time and end time.',
  exceedingOvertimeCaution:
    '_Cannot add more time to the overtime than has been worked.',
  removeInvalidDaysCaution: '_Remove invalid days to register entries.',
  workedHoursCaution: '_Must enter worked hours.',
  assignmentAdded: '_Assignment added successfully.',
  assignmentUpdated: '_Assignment updated successfully.',
  assignmentDeleted: '_Assignment deleted successfully.',
  biogasCustomerTemplateAdded: '_Customer added successfully.',
  biogasCustomerTemplateUpdated: '_Customer updated successfully.',
  biogasCustomerTemplateDeleted: '_Customer deleted successfully.',
  biogasAddressAdded: '_Address added successfully.',
  biogasAddressUpdated: '_Address updated successfully.',
  biogasAddressDeleted: '_Address deleted successfully.',
  biogasAnalysisAdded: '_Analysis added successfully.',
  biogasAnalysisUpdated: '_Analysis updated successfully.',
  biogasAnalysisDeleted: '_Analysis deleted successfully.',
  biogasVehicleAdded: '_Vehicle added successfully.',
  biogasVehicleUpdated: '_Vehicle updated successfully.',
  biogasVehicleDeleted: '_Vehicle deleted successfully.',
};

export const COMMON_MESSAGES = {
  addAndDragAndDropProduct: `_Add the products you want to be included in this template. Drag and drop to reorder the
  products`,
  templateRulePastDay: '_The date has passed, this rule will never run again.',
  addRuleTimeInfo:
    '_24:00 is written as 00:00, two equal times mean that the rule is active for 24 hours',
  addTemplateFieldInfo:
    // eslint-disable-next-line max-len
    '_Time field, are fields that are usually used for Inconvenient working hours. These can have rules attached to them.',
  addTemplateRuleInfo:
    // eslint-disable-next-line max-len
    '_Here are the rules for Inconvenient working hours. By entering the time, date and day, various fields can be filled in automatically.',
  addTemplateTimeFieldValidation:
    '_At least one time field must be added before rules can be created',
  knowlendgeBankInfo:
    '_In the knowledge bank you will find guides and help articles',
  licenceContactInfo:
    '_To extend your license contact us at phone number: 0514-650 200',
  unasnweredInvite:
    '_ The following users have received an invitation but have not yet responded',
  linkOnInvite: '_A link will be created when you send the invitation',
  lockedDayInfo: '_The day is locked and no changes can be made',
  ownExpensesInfo:
    // eslint-disable-next-line max-len
    '_When you have added departure and return, this tab will be filled with dates and you get to register own expenses.',
  registerInviteInfo:
    // eslint-disable-next-line max-len
    '_Someone has invited you to use DVTime. If you already have a DVTime account and want to use the same email, you can choose to log in',
  registerBiogasInfo:
    '_You need to select a customer first to be able to see available analyses, vehicle / weight, etc.',
  biogasWeightInfo:
    '_Calculate net weight by adding gross weight or volume or add net weight manually',
  vacationText: '_Vacation',
  sickText: '_Sick',
  absenceText: '_Absence',
  pwaInstallInfo:
    '_For quicker and easier access, add DVTime to your home screen!',
  addToHome: '_Add DVTime to your Home Screen',
  editDateCaution:
    // eslint-disable-next-line max-len
    '_You are changing the date of an already saved entry. This action cannot be undone—please double-check before saving',
};

export const MESSAGES_TRANSLATION_KEYS = {
  mandatoryFieldErrorMsg: '__validation_required_error',
  emailFieldErrorMsg: '__validation_invalid_email_error',
  maxLengthErrorMsg: '__validation_max_length_error',
  dateErrorMsg: '__valid_date_error',
  productDeleteConfirmationMsg: '__product_delete_confirmation',
  productStatusDeleteConfirmationMsg: '__product_status_delete_confirmation',
  customerDeleteConfirmationMsg: '__customer_delete_confirmation',
  loggedInSuccessfully: '__logged_in_successfully',
  loginUnsuccessful: '__login_unsuccessful',
  userNotFound: '__user_not_found',
  forgotPasswordSuccessful: '__forgot_password_successful',
  internalServerError: '__internal_server_error',
  tokenExpired: '__token_expired',
  addAndDragAndDropProduct: '__add_and_drag_and_drop_product',
  templateDeleteConfirmationMsg: '__template_delete_confirmation',
  productAdded: '__product_added',
  productUpdated: '__product_updated',
  productDeleted: '__product_deleted',
  statusAdded: '__status_added',
  statusUpdated: '__status_updated',
  statusDeleted: '__status_deleted',
  templateAdded: '__template_added',
  templateUpdated: '__template_updated',
  templateDeleted: '__template_deleted',
  customerAdded: '__customer_added',
  customerUpdated: '__customer_updated',
  customerDeleted: '__customer_deleted',
  selectProductForAddTemplate: '__select_product_for_add_template',
  selectProductForUpdateTemplate: '__select_product_for_update_template',
  timeGroupDeleteConfirmationMsg: '__time_group_delete_confirmation',
  timeObjectDeleteConfirmationMsg: '__time_object_delete_confirmation',
  timeTypeDeleteConfirmationMsg: '__time_type_delete_confirmation',
  overtimeTemplateDeleteConfirmationMsg:
    '__overtime_template_delete_confirmation',
  fileFormatErrorMsg: '__file_format_error',
  fileDataErrorMsg: '__file_data_error',
  fileImportErrorMsg: '__file_import_error',
  importSuccess: '_import_success',
  importFailure: '_import_failure',
  timeGroupAdded: '__time_group_added',
  timeGroupUpdated: '__time_group_updated',
  timeGroupDeleted: '__time_group_deleted',
  timeTypeAdded: '__time_type_added',
  timeTypeUpdated: '__time_type_updated',
  timeTypeDeleted: '__time_type_deleted',
  timeObjectAdded: '__time_object_added',
  timeObjectUpdated: '__time_object_updated',
  timeObjectDeleted: '__time_object_deleted',
  overtimeTemplateAdded: '__overtime_template_added',
  overtimeTemplateUpdated: '__overtime_template_updated',
  overtimeTemplateDeleted: '__overtime_template_deleted',
  addTemplateModeSelection: '__select_overtime_timeRegMode',
  addTimeFieldDuplicate: '__add_timeField_duplicate_error',
  addRuleDateValidation: '__overtime_template_date_validation',
  addRuleDayValidation: '__overtime_template_day_validation',
  timeInputValidation: '__time_input_validation',
  templateRulePastDay: '__overtime_rule_pastday',
  roleAdded: '__role_added',
  roleUpdated: '__role_updated',
  roleDeleted: '__role_deleted',
  roleDeleteConfirmationMsg: '__role_delete_confirmation',
  selectCustomer: '__select_customer',
  selectProduct: '__select_product',
  addRuleTimeInfo: '__add_rule_timeInfo',
  addTemplateFieldInfo: '__add_template_fieldInfo',
  addTemplateRuleInfo: '__add_template_ruleInfo',
  addTemplateTimeFieldValidation: '__add_template_timefield_validation',
  productEntryDeleteConfirmationMsg: '__product_entry_delete_confirmation',
  biogasEntryDeleteConfirmationMsg: '__biogas_entry_delete_confirmation',
  productEntryAdded: '__product_entry_added',
  productEntryUpdated: '__product_entry_updated',
  productEntryDeleted: '__product_entry_deleted',
  mileageAdded: '__mileage_added',
  mileageUpdated: '__mileage_updated',
  mileageDeleted: '__mileage_deleted',
  milageDeleteConfirmationMsg: '__mileage_delete_confirmation',
  knowlendgeBankInfo: '__knowledge_bank_info',
  licenceContactInfo: '__licence_contact_info',
  unasnweredInvite: '__unanswered_invite',
  userDeleteConfirmationMsg: '__user_delete_confirmation',
  userAdded: '__user_added',
  userUpdated: '__user_updated',
  userDeleted: '__user_deleted',
  sendInviteMessage: '__send_invite_txt_message',
  linkOnInvite: '__link_onInvite',
  userInvited: '__user_invited',
  breakValidation: '__break_time_validation',
  lockedDayInfo: '__locked_day_info',
  addRoleValidation: '__add_role_validation',
  ownExpensesInfo: '__own_expenses_info',
  selectAllowance: '__select_allowance',
  allowanceAdded: '__allowance_added',
  allowanceUpdated: '__allowance_updated',
  allowanceDeleted: '__allowance_deleted',
  allowanceDeleteConfirmationMsg: '__allowance_delete_confirmation',
  allowanceTimeCaution: '__allowance_time_caution',
  summaryLockSuccess: '__summary_locked',
  summaryUnlockSuccess: '__summary_unlocked',
  startAndEndTimeValidation: '__start_and_end_time_validation',
  exceedingBreaksCaution: '__exceeding_breaks_caution',
  overlappingBreaksCaution: '__overlapping_breaks_caution',
  selectDayCaution: '__select_day_caution',
  registerInviteInfo: '__register_invite_info',
  favSuccess: '__mark_report_fav',
  unFavSuccess: '__mark_report_unfav',
  timeAdded: '__time_added',
  timeUpdated: '__time_updated',
  timeDeleted: '__time_deleted',
  exceedingAbsenceCaution: '__exceeding_absence_caution',
  vacationCaution: '__vacation_caution',
  exceedingTimeBankInCaution: '__exceeding_time_bank_in_caution',
  timeBankCaution: '__time_bank_caution',
  otRuleTimeValidation: '__ot_rule_time_validation',
  dateOrderValidation: '__date_order_validation',
  timeInBankCaution: '__time_in_bank_caution',
  startAndEndTimeCaution: '__start_and_end_time_caution',
  exceedingOvertimeCaution: '__exceeding_overtime_caution',
  removeInvalidDaysCaution: '__remove_invalid_days_caution',
  workedHoursCaution: '__worked_hours_caution',
  editMasterRoleError: '__edit_role_error',
  editOtherProductEntryError: '__edit_other_product_reg_error',
  assignmentAdded: '__assignment_added',
  assignmentUpdated: '__assignment_updated',
  assignmentDeleted: '__assignment_deleted',
  assignmentDeleteConfirmationMsg: '__assignment_delete_confirmation',
  biogasCustomerDeleteConfirm: '__biogas_customer_delete_confirm',
  biogasAddressDeleteConfirm: '__biogas_address_delete_confirm',
  biogasAnalysisDeleteConfirm: '__biogas_analysis_delete_confirm',
  biogasVehicleDeleteConfirm: '__biogas_vehicle_delete_confirm',
  addAddressValidation: '__add_address_validation',
  maxAllowedValue: '__max_allowed_number_validation',
  registerBiogasInfo: '__register_biogas_info_user',
  biogasCustomerTemplateAdded: '__biogas_customer_add',
  biogasCustomerTemplateUpdated: '__biogas_customer_edit',
  biogasCustomerTemplateDeleted: '__biogas_customer_delete',
  biogasAddressAdded: '__biogas_address_add',
  biogasAddressUpdated: '__biogas_address_edit',
  biogasAddressDeleted: '__biogas_address_delete',
  biogasAnalysisAdded: '__biogas_analysis_add',
  biogasAnalysisUpdated: '__biogas_analysis_edit',
  biogasAnalysisDeleted: '__biogas_analysis_delete',
  biogasVehicleAdded: '__biogas_vehicle_add',
  biogasVehicleUpdated: '__biogas_vehicle_edit',
  biogasVehicleDeleted: '__biogas_vehicle_delete',
  biogasWeightInfo: '__biogas_weight_info',
  vacationText: '__vacation_text',
  sickText: '__sick_text',
  absenceText: '__absence_text',
  phoneErrorMsg: '__phone_error_msg',
  pwaInstallInfo: '__pwa_install_info',
  addToHome: '__add_to_home',
  editDateCaution: '__edit_date_caution',
  multiStatusChangeCaution: '__multi_status_change_caution',
};
